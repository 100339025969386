export const home = {
  en: "Home",
  id: "Beranda",
};
export const service = {
  en: "Services",
  id: "Layanan",
};
export const list_of_service = {
  en: "List of Services ",
  id: "Keahlian Kami",
};
export const desc_service = {
  en: "Our primary service focuses on customer satisfaction with in-depth discussion in designing innovative solutions, whiles honoring relevant tax advice is what our company strives for. We are here to collaborate and provide assistance for all your financial and tax needs. Our service focuses on several technical things, including:",
  id: "Layanan utama kami berfokus pada kepuasan pelanggan melalui diskusi mendalam dalam merancang solusi inovatif sambil tetap berpegang pada peraturan yang berlaku. Kami hadir untuk berkolaborasi dan membantu kebutuhan finansial dan perpajakan Anda. Layanan kami berfokus pada beberapa hal teknis berikut.",
};
export const about = {
  en: "About Us",
  id: "Tentang Kami",
};
export const about_desc = {
  en: "Our motivation in delivering quality service is deeply rooted in our advisors. By combining a solid technical foundation and embracing Indonesia’s dynamic tax climate, we strive to help achieve your financial and taxation needs. Find out more from our profile below.",
  id: "Motivasi kami dalam memberikan layanan berkualitas berakar pada tim kami Dengan menggabungkan pengalaman teknis yang kuat serta pengetahuan iklim perpajakan Indonesia yang dinamis, kami membantu memenuhi kebutuhan keuangan dan perpajakan Anda. Selengkapnya terdapat pada profil kami di bawah ini.",
};
export const about_desc_detail = {
  en: "We are Indonesian tax practitioners with a worldwide perspective with the common goal of advancing the tradition of a noble profession while establishing respectable institution. We partner with business leaders to address their most pressing challenges and seize their most promising possibilities in a thorough understanding of domestic and international tax world. <br></br> At Provisio, our services are more than a regulatory obligation. With over a decade of hands-on experience handling a variety of unique circumstances from a diverse industrial background, we strive to add value to our clients' businesses by adopting transformational approach that benefits all stakeholders and drive positive change.",
  id: "Kami adalah praktisi perpajakan Indonesia yang berwawasan dunia dengan tujuan memajukan profesi mulia konsultan pajak sekaligus membangun institusi yang terhormat. Kami bermitra dengan para pemimpin bisnis untuk memberikan solusi atas tantangan perpajakan mereka dan memanfaatkan peluang bisnis yang paling menjanjikan dengan pemahaman menyeluruh tentang regulasi pajak domestik dan internasional.<br></br>  Di Provisio, layanan kami lebih dari sekadar kewajiban pemenuhan peraturan pajak. Dengan lebih dari satu dekade pengalaman menangani perpajakan dari latar belakang industri yang beragam, kami berusaha untuk memberikan nilai tambah yang positif bagi bisnis klien kami dengan pendekatan transformasional yang menguntungkan semua pemangku kepentingan dan mendorong perubahan.  ",
};
export const teamwork = {
  en: "Teamwork",
  id: "Tim Kami",
};
export const desc_about = {
  en: "Our motivation in delivering quality service is deeply rooted in our advisors. By combining a solid technical foundation and embracing Indonesia’s dynamic tax climate, we strive to help achieve your financial and taxation needs. Find out more from our profile below.",
  id: "Motivasi kami dalam memberikan layanan berkualitas berakar kuat pada para penasihat kami. Dengan menggabungkan dasar teknis yang kuat dan merangkul iklim perpajakan Indonesia yang dinamis, kami berusaha untuk membantu memenuhi kebutuhan keuangan dan perpajakan Anda. Cari tahu lebih lanjut dari profil kami di bawah ini.",
};
export const visionmision = {
  en: "Vision & Mission",
  id: "Visi & Misi",
};
export const vision = {
  en: "Vision",
  id: "Visi",
};
export const mision = {
  en: "Mission",
  id: "Misi",
};
export const core = {
  en: "Core Value",
  id: "Nilai Utama",
};
export const expert = {
  en: "Experts",
  id: "Pakar",
};
export const our_expert = {
  en: "Our Experts",
  id: "Para Ahli Kami",
};
export const who_we_are = {
  en: "Who We Are",
  id: "Siapa Kami",
};
export const are_your_looking_for_a = {
  en: "DEDICATED to EXCELLENCE",
  id: "DEDIKASI UNTUK KEUNGGULAN",
};
export const professional_tax_consultan = {
  en: "Tax Consultant",
  id: "Konsultan Pajak",
};
export const contact_us_right_now = {
  en: "Contact us right now!",
  id: "Hubungi kami sekarang juga!",
};
export const our_expert_desc = {
  id: "Kami bekerja dengan para profesional, berpengalaman di bidangnya selama bertahun-tahun. Adalah suatu kehormatan bagi kami untuk dapat membantu bisnis Anda.",
  en: "We work with professionals, experienced in their fields for many years. It is an honor to be able to help your business.",
};
export const people = {
  en: "People",
  id: "Orang",
};
export const desc_people = {
  en: "We are a team, we work together in realizing the solution to your problem, we have the same vision standing here carrying out the same mission, doing the best because we are the best, we are very happy to help you, and we are very happy your business grows after this cooperation",
  id: "Kami adalah tim, kami bekerja sama dalam mewujudkan solusi untuk masalah Anda, kami memiliki visi yang sama berdiri di sini menjalankan misi yang sama, melakukan yang terbaik karena kami adalah yang terbaik, kami sangat senang membantu Anda, dan kami sangat senang bisnis Anda tumbuh setelah kerjasama ini",
};
export const industries = {
  en: "Industries",
  id: "Industri",
};
export const careers = {
  en: "Careers",
  id: "Karir",
};
export const sub_careers = {
  en: "Elevate your future and grow with us",
  id: "Naikkan masa depan Anda dan tumbuh bersama kami",
};
export const insight = {
  en: "News & Updates",
  id: "Berita & Isu Terkini",
};
export const sub_insight = {
  en: "Our perspective and latest insights to expand tax, economic, and global issues related contents on the trends shaping the future of business",
  id: "Perspektif dan wawasan terbaru kami untuk memperluas konten terkait pajak, ekonomi, dan global tentang tren yang membentuk masa depan bisnis",
};
export const how_we_can = {
  en: "How we can help you?",
  id: "Bagaimana Kami Dapat Membantu Anda?",
};
export const desc_how_we = {
  en: "Send us email or directly phone our number. We will connect to you at the soonest.",
  id: "Hubungi kami melalui surel dan telepon. Kami akan segera merespon anda.",
};
export const contact = {
  en: "Contact Us",
  id: "Hubungi Kami",
};
export const contact_details = {
  en: "Contact Details",
  id: "Kontak Detail",
};
export const sub_contact = {
  en: "We are here to connect with you",
  id: "Kami di sini untuk terhubung dengan Anda",
};
export const desc_contact = {
  en: "For inquiries or requests that require a more personal response, we will make every attempt to respond within 24 hours. You may contact us by phone and/or visit our office.",
  id: "Kami akan berusaha merespon pertanyaan dan permintaan anda dalam waktu 24 jam. Anda juga dapat menghubungi kami melalui nomor telepon kami.",
};
export const write = {
  en: "Write Us",
  id: "Tulis pesan",
};
export const call = {
  en: "Call Us",
  id: "Hubungi kami",
};
export const call_now = {
  en: "Call Us Now",
  id: "Hubungi kami sekarang",
};
export const location = {
  en: "Our Location",
  id: "Lokasi kami",
};
export const mail = {
  en: "Mail Us",
  id: "Email kami",
};
export const mail_now = {
  en: "Email Us Now",
  id: "Email kami sekarang",
};
export const mail_us = {
  en: "Mail Us",
  id: "Surel Us",
};
export const sosmed = {
  en: "Social Media",
  id: "Media Sosial",
};
export const read_more = {
  en: "Read More",
  id: "Lebih Lanjut",
};
export const news_and_update = {
  en: "News And Updates",
  id: "Berita dan Lainnya",
};
export const find_out_more = {
  en: "Find Out More",
  id: "Lebih Lanjut",
};
export const submit_now = {
  en: "Submit Now",
  id: "Kirim",
};
export const send_a_message = {
  en: "Send a Message",
  id: "Tinggalkan Pesan",
};
export const address = {
  en: "Address",
  id: "Alamat",
};
export const phone = {
  en: "Phone",
  id: "Telepon",
};
export const email = {
  en: "Email",
  id: "Surel",
};
export const looking_for = {
  en: "Looking For",
  id: "Sedang mencari",
};
export const partner = {
  en: "Partner at tax?",
  id: "Mitra pajak?",
};
export const articles = {
  en: "Articles",
  id: "Artikel",
};
export const categories = {
  en: "Categories",
  id: "Kategori",
};
export const pagefirst = {
  en: "First",
  id: "Awal",
};
export const pagelast = {
  en: "Last",
  id: "Akhir",
};

export const enter_your_email = {
  en: "Enter your email ",
  id: "masukan alamat surel Anda",
};
export const title_our_service = {
  en: "Our Services",
  id: "Layanan Kami",
};

export const sub_our_service = {
  en: "Commitment to Deliver Excellence",
  id: "Berkomitmen Untuk Memberikan Keunggulan",
};

export const desc_our_service = {
  en: "Our primary service focuses on customer satisfaction with in-depth discussion in designing innovative solutions whiles adhering to prevailing regulations. We are here to collaborate and provide assistance for all your financial and tax needs.",
  id: "Layanan utama kami berfokus pada kepuasan pelanggan melalui diskusi mendalam dalam merancang solusi inovatif sambil tetap berpegang pada peraturan yang berlaku. Kami hadir untuk berkolaborasi dan membantu kebutuhan finansial dan perpajakan Anda.",
};

export const what_we_do = {
  en: "What We Do",
  id: "Layanan Yang Kami Berikan",
};

export const our_team = {
  en: "Our Team",
  id: "Tim Kerja",
};

export const core_value = {
  en: "Core Value",
  id: "Nilai Utama",
};

export const leaders = {
  en: "Leaders",
  id: "Pemimpin",
};

export const our_manager = {
  en: "Our Managers",
  id: "Manajer Kami",
};

export const recent_post = {
  en: "Recent Post",
  id: "Postingan Terbaru",
};

export const be_provisioner = {
  en: "Join Our Team",
  id: "Bergabunglah dengan Tim Kami",
};

export const be_provisioner_desc = {
  en: "Our team consists of experts with years of hands-on experience that is committed and passionate to deliver the best quality outcome for all our clients. With a unified purpose and passion, our team will work together to not only achieve unified company-wide goals but also help in achieving individual growth & goals.",
  id: "Tim kami terdiri dari para ahli dengan pengalaman bertahun-tahun yang berkomitmen dan bersemangat untuk memberikan hasil kualitas terbaik untuk semua klien kami. Dengan tujuan dan semangat yang bersatu, tim kami akan bekerja sama untuk tidak hanya mencapai tujuan perusahaan secara keseluruhan tetapi juga membantu dalam mencapai pertumbuhan & tujuan individu.",
};

export const title_job = {
  en: "Job Title",
  id: "Lowongan",
};

export const grade = {
  en: "Grade",
  id: "Tingkat",
};
