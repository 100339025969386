import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  about,
  careers,
  contact,
  core,
  home,
  insight,
  mision,
  people,
  service,
  vision,
} from "../utils/string";

import axios from "axios";
import { API_SUBSCRIBE, API_NEWS_LANDING } from "../utils/api";
import logo_full_white from "../assets/images/logo_white.png";

class Footer extends Component {
  constructor(props) {
    super(props);
    const main = props.main;

    this.state = {
      news: [],
      message: "",
    };
  }

  componentDidMount() {
    this.getNews();
  }

  getNews() {
    const headers = {};
    axios
      .get(API_NEWS_LANDING, { headers: headers })
      .then((res) => {
        const news = res.data.news;
        this.setState({ news });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    var param = {
      email: data.get("email"),
      name: data.get("name"),
      message: data.get("message"),
    };

    const headers = {};
    axios
      .post(API_SUBSCRIBE, param, { headers: headers })
      .then((res) => {
        // var res = JSON.stringify({error:true,msg:"Thank you for the message you sent, we will immediately respond to the message."});
        var data = JSON.parse(res);
        if (data.error) {
          const message = (
            <div className="contact-form-error alert alert-danger mt-4">
              <strong>Error!</strong> {data.msg}
              <span className="mail-error-message text-1 d-block" />
            </div>
          );
          this.setState({ message });
        } else {
          const message = (
            <div className="contact-form-success alert alert-success mt-4">
              <strong>Success!</strong> {data.msg}
            </div>
          );
          this.setState({ message });
        }
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { news, message } = this.state;
    return (
      <div>
        <div className="footer-top">
          <div className="container" />
        </div>
        <footer className="footer widget-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 reponsive-mb30">
                <div className="widget clearfix pt-0">
                  <img
                    className="pt-2"
                    src={logo_full_white}
                    style={{ width: "120px" }}
                    alt="logo"
                  />
                  {/* <ul className="flat-information mt-4">
                                        <li>
                                            <p style={{lineHeight:'18px'}} className="info-text" dangerouslySetInnerHTML={ { __html: this.props.main.ADDRESS } }></p>
                                        </li>

                                    </ul>            */}
                </div>
              </div>
              {/* /.col-md-3 */}
              <div className="col-lg-3 col-sm-6 reponsive-mb30">
                <div className="widget widget-out-link clearfix">
                  <a
                    href={"tel:" + this.props.main.NO_TELP}
                    className="text-decoration-none"
                    target="_blank"
                    rel="noreferrer"
                    title="Call Us"
                  >
                    <h4 className="widget-title">
                      <i
                        className="fa fa-phone mr-3"
                        style={{
                          color: "#0c4491",
                          background: "#ffff",
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                          lineHeight: "40px",
                        }}
                      />
                      {this.props.main.NO_TELP}
                    </h4>
                  </a>
                  {/* <a
                    href={"tel:" + this.props.main.NO_TELP2}
                    className="text-decoration-none"
                    target="_blank"
                    rel="noreferrer"
                    title="Call Us"
                  >
                    <h4 className="widget-title">
                      <i
                        className="fa fa-phone mr-3"
                        style={{
                          color: "#0c4491",
                          background: "#ffff",
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                          lineHeight: "40px",
                        }}
                      />
                      {this.props.main.NO_TELP2}
                    </h4>
                  </a> */}
                  {/* <ul className="one-half">
                                    <li><a href="/">Home</a></li>
                                    <li><Link to="/service"> {service[window.lang]}</Link></li>
                                    <li><Link to="/about"> {about[window.lang]}</Link></li>
                                    <li><Link to="/case">{industries[window.lang]}</Link></li>
                                    <li><Link to="/career">{careers[window.lang]}</Link></li>
                                    </ul>
                                    <ul className="one-half">
                                    <li><Link to="/tax/page/1"> {insight[window.lang]}</Link></li>
                                    <li><Link to="/legal/page/1"> Tax & Legal Regulation</Link></li>
                                    <li><Link to="/contact"> {contact[window.lang]}</Link></li>
                                    </ul> */}
                </div>
              </div>
              {/* /.col-md-3 */}
              <div className="col-lg-4 col-sm-6 reponsive-mb30">
                <div className="widget widget-recent-new">
                  <a
                    href={"mailto:" + this.props.main.EMAIL}
                    className="text-decoration-none"
                    target="_blank"
                    rel="noreferrer"
                    title="Mail Us"
                  >
                    <h4 className="widget-title">
                      <i
                        className="fa fa-envelope mr-3"
                        style={{
                          color: "#0c4491",
                          background: "#ffff",
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                          lineHeight: "40px",
                        }}
                      />
                      {this.props.main.EMAIL}
                    </h4>
                  </a>
                  {/* <ul className="popular-new">
                                    {news && news.map((d) => (
                                        <li>
                                            <div className="text">
                                            <p><a href="/#">{d.TITLE}</a></p>
                                            <span>{d.DATE_NEWS}</span>
                                            </div>
                                        </li>
                                    ))}
                                    </ul> */}
                </div>
              </div>
              {/* /.col-md-3 */}
              <div className="col-lg-2 reponsive-mb30">
                <div className="widget widget-letter">
                  <h3 className="widget-title">
                    <ul className="flat-footer social-links pr-2">
                      <li className="enam pr-4 ml-0">
                        <a href={this.props.main.FB}>
                          <i
                            className="fa fa-facebook-square"
                            style={{
                              color: "#0c4491",
                              background: "#ffff",
                              borderRadius: "50%",
                              width: "40px",
                              height: "40px",
                              lineHeight: "40px",
                            }}
                          />
                        </a>
                      </li>
                      <li className="ml-0">
                        <a href={this.props.main.IG}>
                          <i
                            className="fa fa-instagram"
                            style={{
                              color: "#0c4491",
                              background: "#ffff",
                              borderRadius: "50%",
                              width: "40px",
                              height: "40px",
                              lineHeight: "40px",
                            }}
                          />
                        </a>
                      </li>
                    </ul>
                  </h3>
                </div>
              </div>
              <ul className="flat-information mt-3 pl-3 pr-3 pb-2">
                <li>
                  <p
                    style={{ lineHeight: "14px" }}
                    className="info-text"
                    dangerouslySetInnerHTML={{
                      __html: this.props.main.ADDRESS,
                    }}
                  ></p>
                </li>
              </ul>
              <div className="menu footer-classic header-style1">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="flat-wrap-header">
                        <div className="nav-wrap clearfix">
                          <nav id="mainnav" className="mainnav">
                            <ul className="menu-footer">
                              <li className="active mr-4">
                                <p>
                                  <a href="/">{this.props.main.HOME}</a>
                                </p>
                              </li>
                              <li className="mr-4">
                                <p>
                                  <a href="/service">
                                    {this.props.main.SERVICES}
                                  </a>

                                  {/* <Link to="/service">
                                    {" "}
                                    {this.props.main.SERVICES}
                                  </Link> */}
                                </p>
                              </li>
                              <li className="mr-4">
                                <p>
                                  {/* <Link to="/about">
                                    {" "}
                                    {this.props.main.ABOUT}
                                  </Link> */}
                                  <a href="/about"> {this.props.main.ABOUT}</a>
                                </p>
                              </li>
                              <li className="mr-4">
                                <p>
                                  {/* <Link to="/tax/page/1">
                                    {" "}
                                    {this.props.main.NEWS}
                                  </Link> */}
                                  <a href="/tax/page/1">
                                    {" "}
                                    {this.props.main.NEWS}
                                  </a>
                                </p>
                              </li>
                              <li className="mr-4">
                                <p>
                                  {/* <Link to="/career">
                                    {this.props.main.CAREERS}
                                  </Link> */}
                                  <a href="/career">
                                    {" "}
                                    {this.props.main.CAREERS}
                                  </a>
                                </p>
                              </li>
                              <li className="mr-4">
                                <p>
                                  {/* <Link to="/contact">
                                    {" "}
                                    {this.props.main.CONTACTS}
                                  </Link> */}
                                  <a href="/contact">
                                    {" "}
                                    {this.props.main.CONTACTS}
                                  </a>
                                </p>
                              </li>
                            </ul>
                            {/* /.menu */}
                          </nav>
                          {/* /.mainnav */}
                        </div>
                        {/* /.nav-wrap */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="bottom central">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center pb-4">
                <p className="info-text" style={{ fontSize: "13px" }}>
                  2021 ©{" "}
                  <span className="text-color-light">Provisio Consulting</span>{" "}
                  - Copyright All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
